import { useLayoutEffect, useRef } from 'react';
import { useCallbackRef } from './use-callback-ref';

type OnFocusOutside = (event: FocusEvent) => void;

/**
 * Hook to react on focus moving outside of react tree including portals
 *
 * https://github.com/facebook/react/issues/19637#issuecomment-850412650
 */
export const useFocusOutside = (onFocusOutside?: OnFocusOutside) => {
  const handleFocusOutside = useCallbackRef(onFocusOutside);
  const isFocusInsideReactTreeRef = useRef(false);

  useLayoutEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      if (!isFocusInsideReactTreeRef.current) {
        handleFocusOutside(event);
      }
    };
    document.addEventListener('focusin', handleFocus);
    return () => document.removeEventListener('focusin', handleFocus);
  }, [handleFocusOutside]);

  return {
    onFocusCapture: () => {
      isFocusInsideReactTreeRef.current = true;
    },
    onBlurCapture: () => {
      isFocusInsideReactTreeRef.current = false;
    },
  };
};
