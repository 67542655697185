import { useLoadableQuery } from '@apollo/client';
import { startTransition, useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { gql } from '@/__generated__';

const TypeaheadQuery = gql(`
query TypeaheadQuery($query: String!, $limit: Int = 3) {
  typeahead(input: {text: $query, limit: $limit, type: [User]}) {
    users {
      id
      ...UserBadgeFragment
    }
  }
}
`);

export const useTypeahead = ({
  defaultValue,
  limit,
}: {
  defaultValue?: string;
  limit?: number;
}) => {
  const [loadQuery, queryRef, refHandlers] = useLoadableQuery(TypeaheadQuery, {
    fetchPolicy: 'network-only',
  });

  const [value, setValue] = useState(defaultValue || '');

  const debouncedLoad = useDebouncedCallback<typeof loadQuery>((vars) => {
    startTransition(() => {
      loadQuery(vars);
    });
  }, 200);

  const onValueChange = useCallback(
    (value: string | null) => {
      const query = value || '';
      if (query.length === 0) {
        refHandlers.reset();
        debouncedLoad.cancel();
      } else {
        debouncedLoad({ query, limit });
      }

      setValue(query);
    },
    [debouncedLoad, limit, refHandlers],
  );

  return {
    value,
    onValueChange,
    queryRef,
  };
};
